import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import IntroMobileAnimation from "../resources/animation/quiz/intro-mobile.json"
import HairMobileAnimation from "../resources/animation/quiz/hair-mobile.json"
import ScalpMobileAnimation from "../resources/animation/quiz/scalp-mobile.json"
import IntroAnimation from "../resources/animation/quiz/intro.json"
import HairAnimation from "../resources/animation/quiz/hair.json"
import ScalpAnimation from "../resources/animation/quiz/scalp.json"
import ThankYouMobileAnimation from "../resources/animation/quiz/thank-you-mobile.json"
import ThankYouAnimation from "../resources/animation/quiz/thank-you.json"
import { isBrowser } from "../services/core/browser"
import { createGlobalStyle } from "styled-components"
import breakpoints from "../styles/breakpoints"
import {
  trackCustomEvent,
  trackData,
  trackLead,
  trackUser,
} from "../services/tracking/blotout"

const serialize = require("form-serialize")

const GlobalStyle = createGlobalStyle`
  div.acsb-trigger {
    @media (min-width: ${breakpoints.sm}) and (max-width: 768px) {
      inset: auto 33px 33px auto !important;
    }
  
    @media (min-width: ${breakpoints.air}) {
      inset: auto 33px 33px auto !important;
    }
  
    @media (min-width: ${breakpoints.ipad}) {
      inset: auto 34px 34px auto !important;
    }
  }
`

const QuizPage = ({ location, data }) => {
  const [isEventListenerRegistered, setIsEventListenerRegistered] =
    useState(false)
  const [user, setUser] = useState({})
  const [quizIndex, setQuizIndex] = useState(0)
  const [sectionIndex, setSectionIndex] = useState(0)
  const [quizAnswers, setQuizAnswers] = useState({})
  const [overlayVisible, setOverlayVisible] = useState(true)
  const [overlayIndex, setOverlayIndex] = useState(0)
  const [width, setWidth] = useState(0)
  const [quizFlow, setQuizFlow] = useState([])
  const path = location.pathname
  let formRef = useRef(null)
  const { page } = data
  const animations = [
    { mobile: IntroMobileAnimation, desktop: IntroAnimation },
    { mobile: HairMobileAnimation, desktop: HairAnimation },
    { mobile: ScalpMobileAnimation, desktop: ScalpAnimation },
    { mobile: ThankYouMobileAnimation, desktop: ThankYouAnimation },
  ]

  const { metaTitle, metaDescription, socialMediaImage, blocks } = page

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  const handleOverlayIndex = () => {
    let nextIndex = quizIndex

    if (nextIndex < animations.length) {
      setOverlayIndex(nextIndex)
      setOverlayVisible(true)
    } else {
      setOverlayVisible(false)
    }
  }

  const getWindowWidth = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (isBrowser()) {
      getWindowWidth()
      window.addEventListener("resize", getWindowWidth)

      if (
        isEventListenerRegistered ||
        window.octaneListenerRegistered === true
      ) {
        return
      }

      setIsEventListenerRegistered(true)
      window.octaneListenerRegistered = true

      document.addEventListener(
        "octane.quiz.questionAnswered",
        (e) => {
          const detail = e.detail
          const { questionType, questionValue, questionTitle } = detail

          const userData = {}
          if (questionType === "email") {
            userData.email = questionValue
          }
          if (
            questionType === "free_form_text" &&
            questionTitle.includes(`What's your name?`)
          ) {
            userData.firstName = questionValue
          }

          const email = userData.email
          const firstName = userData.firstName

          if (email || firstName) {
            let updatedUserData = Object.assign({}, user)

            if (email) {
              updatedUserData.email = email
            }

            if (firstName) {
              updatedUserData.firstName = firstName
            }

            trackData(updatedUserData)
            trackLead("Quiz Lead")
            setUser(updatedUserData)
          }

          if (email) {
            trackUser(email)
          }

          trackCustomEvent("QuizQuestionAnswered");
        },
        { capture: true }
      )

      document.addEventListener(
        "octane.quiz.completed",
        () => {
          trackCustomEvent("QuizCompleted")
        },
        { capture: true }
      )

      document.addEventListener(
        "octane.quiz.accessed",
        () => {
          trackCustomEvent("QuizAccessed")
        },
        { capture: true }
      )

      document.addEventListener(
        "octane.quiz.questionLoaded",
        () => {
          trackCustomEvent("QuizQuestionLoaded")
        },
        { capture: true }
      )

      document.addEventListener(
        "octane.quiz.addToCart",
        () => {
          trackCustomEvent("QuizAddToCart")
        },
        { capture: true }
      )

      document.addEventListener(
        "octane.quiz.addToCartError",
        () => {
          trackCustomEvent("QuizAddToCartError")
        },
        { capture: true }
      )
    }
    return () => {
      window.removeEventListener("resize", getWindowWidth)
    }
  }, [])

  return (
    <Layout quiz={true} footer={false}>
      <GlobalStyle />
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <div
        className="octane-ai-quiz"
        data-quiz-id="TvDTeRd6lrMDYHMj"
        data-embed-type="fullscreen"
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            minHeight: "200px",
          }}
          id="octane-loading-spinner-outer"
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50px", height: "50px", marginTop: "50px" }}>
              <img
                style={{ border: "none" }}
                id="octane-loading-spinner-img"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI0MCIgc3Ryb2tlPSIjY2FjM2VmIiBzdHJva2Utd2lkdGg9IjgiIGZpbGw9Im5vbmUiIHN0cm9rZS1kYXNoYXJyYXk9IjE4MCAxMDAiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgYXR0cmlidXRlVHlwZT0iWE1MIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgNTAgNTAiIHRvPSIzNjAgNTAgNTAiIGR1cj0iMXMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9jaXJjbGU+PC9zdmc+"
                alt="Loading..."
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
      <script
        src="https://app.octaneai.com/2xfh8ruyi1bjxzk4/quiz.js"
        async
        defer
      ></script>
    </Layout>
  )
}

export default QuizPage

export const pageQuery = graphql`
  query QuizpageQuery {
    page: contentfulQuiz(slug: { eq: "quiz" }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      introTrackingLabel
      introCopy {
        raw
      }
      quizStartButtonText
      disclaimerHeadline
      disclaimerCopy
      thankYou {
        raw
      }
      doctor {
        content {
          raw
        }
        image {
          gatsbyImageData(width: 160, quality: 80)
          title
        }
      }      
    }
  }
`
